import * as React from 'react'
import { Linking, StyleSheet, Text, View } from 'react-native'
import { COLORS, FONTS } from '../../../../constants'

const styles = StyleSheet.create({
  container: {
    marginTop: 28,
    marginBottom: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textLabel: {
    fontSize: 14,
    fontFamily: FONTS.MAIN_FONT,
    color: COLORS.LINK,
    textDecorationLine: 'underline',
  },
})

const FAQ_LINK = `${process.env.REACT_APP_BASE_PATH}/faq#9`

export default function Faq() {
  const OpneUrl = (url: string) => {
    Linking.openURL(url)
  }

  return (
    <View style={styles.container}>
      <Text style={styles.textLabel} onPress={() => OpneUrl(FAQ_LINK)}>
        カードがうまく登録できない時は？
      </Text>
    </View>
  )
}
