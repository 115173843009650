import * as React from 'react'
import { Picker, StyleSheet, Text, View } from 'react-native'
import { COLORS, FONTS } from '../../../../constants'
import ValidationErrors from '../../../common/ValidationErrors'

const styles = StyleSheet.create({
  label: {
    marginLeft: 8,
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
    textAlign: 'left',
  },
  requiredLabel: {
    fontSize: 16,
    minWidth: 42,
    textAlign: 'center',
    fontFamily: FONTS.MAIN_FONT,
    color: COLORS.BACKGROUND,
    backgroundColor: COLORS.ACCENT,
    padding: 4,
    letterSpacing: 0,
  },
  labelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    textAlign: 'center',
    marginHorizontal: 16,
  },
  topSpacing: {
    marginTop: 28,
  },
  picker: {
    height: 56,
    width: 'auto',
    marginHorizontal: 16,
    marginTop: 16,
    backgroundColor: COLORS.ACCENT_LIGHT,
  },
  pickerItem: {
    marginLeft: 16,
    fontSize: 14,
  },
})
const DEFAULT_ANSWER = '回答を選択してください'
const ERR_DEFAULT_ANSWER = '回答を選択してください'

interface Props {
  // Survey1
  firstSurveyQuestion: string
  firstSurveyAnswerOptions: Array<string> | undefined
  firstSurveyAnswer: string
  setFirstSurveyAnswer: (selectedValue: string) => void
  setFirstSurveyAnswerValidation: (isValid: boolean) => void
}

export default function FirstSurvey(props: Props) {
  const {
    firstSurveyQuestion,
    firstSurveyAnswerOptions,
    firstSurveyAnswer,
    setFirstSurveyAnswer,
    setFirstSurveyAnswerValidation: setValid,
  } = props

  const [errors, setErrors] = React.useState<Array<string>>([])
  const validateAll = React.useCallback(() => {
    const errorMessages = [firstSurveyAnswer === DEFAULT_ANSWER ? ERR_DEFAULT_ANSWER : []].flat()
    setErrors(errorMessages)
    setValid(errorMessages.length === 0)
  }, [firstSurveyAnswer, setErrors, setValid])

  const onChangeValue = (newValue: string) => {
    setFirstSurveyAnswer(newValue)
  }
  const isFirstRendering = React.useRef(true)
  React.useEffect(() => {
    if (isFirstRendering.current) {
      isFirstRendering.current = false
      return
    }
    validateAll()
  }, [validateAll])

  let pickerItems
  if (firstSurveyAnswerOptions) {
    pickerItems = firstSurveyAnswerOptions.map((s, i) => {
      return <Picker.Item key={i} value={s} label={s} />
    })
  } else {
    pickerItems = null
  }

  return (
    <View style={styles.topSpacing}>
      <View style={styles.labelWrapper}>
        <Text style={styles.requiredLabel}>必須</Text>
        <Text style={styles.label}>{firstSurveyQuestion}</Text>
      </View>
      <ValidationErrors errors={errors} />
      <Picker
        selectedValue={firstSurveyAnswer}
        style={styles.picker}
        itemStyle={styles.pickerItem}
        onValueChange={onChangeValue}>
        {pickerItems}
      </Picker>
    </View>
  )
}
