import * as React from 'react'
import { Linking, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { COLORS, FONTS } from '../../../constants'
import { Domain } from '../../../core'

const styles = StyleSheet.create({
  headlineWrapper: {
    marginTop: 28,
    marginBottom: 8,
  },
  headline: {
    fontSize: 20,
    fontFamily: FONTS.MAIN_FONT,
    marginHorizontal: 16,
    textAlign: 'center',
  },
  labelWrapper: {
    marginTop: 20,
    marginHorizontal: 16,
  },
  label: {
    fontSize: 14,
  },
  input: {
    marginTop: 8,
    height: 44,
    borderWidth: 0,
    padding: 8,
    fontSize: 16,
    textAlign: 'left',
    letterSpacing: 0.5,
  },
  linkWrapper: {
    marginTop: 28,
    marginBottom: 28,
    marginHorizontal: 16,
    alignItems: 'center',
  },
  underLineText: {
    fontSize: 16,
    color: COLORS.LINK,
    textDecorationLine: 'underline',
  },
  border: {
    borderTopWidth: 1,
    borderColor: COLORS.LIGHTGRAY,
  },
})

interface Props {
  membersCard: Domain.MembersCard.Entity | null
}

const QA = `${process.env.REACT_APP_BASE_PATH}/faq#8`
export default function CustomerInfo(props: Props) {
  const OpneUrl = (url: string) => {
    Linking.openURL(url)
  }
  let membersCardNo
  if (props.membersCard) {
    const regexp = /^(\d{1,7})?(\d{1,3})?(\d{1,3})?(\d{1,3})?$/
    const digits = props.membersCard?.membersCardNo
    membersCardNo = regexp
      .exec(digits)
      ?.slice(1)
      .filter(e => e)
      .join(' ')
  } else {
    membersCardNo = ''
  }
  return (
    <View>
      <View style={styles.headlineWrapper}>
        <Text style={styles.headline}>お客様情報</Text>
      </View>
      <View style={styles.labelWrapper}>
        <Text style={styles.label}>メンバーズカード番号</Text>
        <View style={styles.border} />
        <Text style={styles.input}>{membersCardNo}</Text>
      </View>
      <View style={styles.labelWrapper}>
        <Text style={styles.label}>メールアドレス</Text>
        <View style={styles.border} />
        <Text style={styles.input}>{props.membersCard?.email}</Text>
      </View>
      <TouchableOpacity style={styles.linkWrapper}>
        <Text style={styles.underLineText} onPress={() => OpneUrl(QA)}>
          変更、間違えてしまった場合はこちら
        </Text>
      </TouchableOpacity>
    </View>
  )
}
