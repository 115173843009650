import * as React from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useParams } from 'react-router-dom'
import { COLORS, FONTS } from '../../../constants'
import { Domain } from '../../../core'
import { useNavigate } from '../../../lib/hooks'
import Footer from '../../common/Footer'
import Modal from '../../common/Modal'
import GiftList from './GiftList'
import RedeemButton from './RedeemButton'

const styles = StyleSheet.create({
  border: {
    borderTopWidth: 1,
    borderColor: COLORS.LIGHTGRAY,
    marginTop: 28,
  },
  headline: {
    fontSize: 20,
    fontFamily: FONTS.MAIN_FONT,
    marginTop: 28,
    marginHorizontal: 16,
    marginBottom: 0,
    textAlign: 'center',
  },
  button: {
    marginTop: 28,
  },
  modalButton: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 36,
    fontSize: 16,
  },
  modalButtonLabel: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  modalButtonWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  notificationText: {
    margin: 16,
  },
})

interface Props {
  tickets: Array<Domain.Ticket.Entity>
  ticketRedeemResult: Domain.Ticket.Entity | null
  actions: {
    redeemTicket: (ticketId: string, incentiveType: string) => void
    getTickets: () => void
    clearTicketRedeemResult: () => void
  }
}

export default function SpecialGift(props: Props) {
  const { ticketNumber } = useParams()
  const navigate = useNavigate('/tickets')

  const [needsToShowRedeemModal, setNeedsToShowRedeemModal] = React.useState(false)
  const [needsToShowAfterRedeemModal, setNeedsToShowAfterRedeemModal] = React.useState(false)
  const [selectedGiftName, setSelectedGiftName] = React.useState<string>('')
  const [selectedGiftOption, setSelectedGift] = React.useState<string>('')
  const [selectedGiftOptionValidation, isSelectedValidation] = React.useState(false)
  const buttonEnabled = selectedGiftOptionValidation

  const handleOnPressRedeem = () => {
    // pass ticket id(ticketNumber -1 is ticketId)
    const ticketId = props.tickets[ticketNumber - 1].ticketId
    props.actions.redeemTicket(ticketId, selectedGiftOption)
  }

  const showAfterRedeemModal = () => {
    setNeedsToShowAfterRedeemModal(true)
  }

  const onPressRedeem = () => {
    setNeedsToShowRedeemModal(false)
    handleOnPressRedeem()
    showAfterRedeemModal()
  }

  const onPressCancel = () => {
    setNeedsToShowRedeemModal(false)
  }

  const onPressShowModal = () => {
    setNeedsToShowRedeemModal(true)
  }

  const onPressCloseAfterRedeemModal = () => {
    setNeedsToShowAfterRedeemModal(false)
    props.actions.clearTicketRedeemResult()
    // navigate to tickets page
    navigate()
  }

  return (
    <View>
      <Modal
        needsToShow={!!props.ticketRedeemResult && !Object.keys(props.ticketRedeemResult).length}
        title={'ギフトを\n準備しています'}
        description={'チケットと交換できるギフトを準備しています。暫くお待ちください。'}>
        <TouchableOpacity style={styles.modalButton} onPress={props.actions.clearTicketRedeemResult}>
          <Text style={styles.modalButtonLabel}>戻る</Text>
        </TouchableOpacity>
      </Modal>
      <Modal
        needsToShow={!!(needsToShowAfterRedeemModal && props.ticketRedeemResult?.isUsed)}
        title={`${selectedGiftName.replace(' ', '\n')}と\n交換しました`}>
        <TouchableOpacity style={styles.modalButton} onPress={onPressCloseAfterRedeemModal}>
          <Text style={styles.modalButtonLabel}>戻る</Text>
        </TouchableOpacity>
      </Modal>
      <Modal
        needsToShow={needsToShowRedeemModal}
        title={selectedGiftName.replace(' ', '\n')}
        description={'上記の商品と交換しますか？'}>
        <View style={styles.modalButtonWrapper}>
          <TouchableOpacity style={styles.modalButton} onPress={onPressCancel}>
            <Text style={styles.modalButtonLabel}>交換しない</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.modalButton} onPress={onPressRedeem}>
            <Text style={styles.modalButtonLabel}>交換する</Text>
          </TouchableOpacity>
        </View>
      </Modal>
      <ScrollView>
        <Text style={styles.headline}>チケットを交換しましょう</Text>
        <View style={styles.border} />
        <GiftList
          tickets={props.tickets}
          selectedGiftOption={selectedGiftOption}
          setSelectedGiftOption={setSelectedGift}
          setSelectedGiftName={setSelectedGiftName}
          setIsSelectedValidation={isSelectedValidation}
          getTickets={props.actions.getTickets}
        />
        <Text style={styles.notificationText}>※時期により選択できるギフトの内容に変更があります。</Text>
        <RedeemButton onPressShowModal={onPressShowModal} style={styles.button} disabled={!buttonEnabled} />
      </ScrollView>
      <Footer />
    </View>
  )
}
