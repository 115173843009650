import { Domain } from '../../../core'

type couponContents = Domain.LINECoupon.CouponContents

function insertCommentIfExist(feedback: Array<Domain.LINECoupon.Survey>, contents: Array<{}>): Array<{}> {
  if (feedback.length < 3 || !feedback[2].answer) {
    return contents
  } else {
    const elements = [
      {
        type: 'text',
        text: '■私から『ぜひこれを伝えたい！』',
        margin: 'md',
      },
      {
        type: 'text',
        text: `${feedback[2].answer}`,
        wrap: true,
      },
    ]
    // Insert comment element using splice() with specified index.
    contents.splice(14, 0, ...elements)
    return contents
  }
}

function createBaseContents(
  name: string,
  approvalNo: string,
  couponContents: couponContents,
  feedback?: Array<Domain.LINECoupon.Survey>,
): Array<{}> {
  return [
    {
      type: 'text',
      text: `${name}さんからご紹介案内とご紹介クーポンが届きました！`,
      margin: 'sm',
      wrap: true,
    },
    {
      type: 'text',
      text: '＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝',
    },
    {
      type: 'text',
      text: '【ご紹介クーポン】',
      margin: 'md',
    },
    {
      type: 'text',
      text: '全国のメガネスーパーなどの店舗で以下のクーポンがご使用頂けます。',
      margin: 'md',
      wrap: true,
    },
    {
      type: 'image',
      url: `${couponContents.couponImageUrl}`,
      position: 'relative',
      margin: 'xl',
      align: 'center',
      gravity: 'top',
      size: 'full',
      aspectMode: 'fit',
    },
    {
      type: 'separator',
      margin: 'xxl',
      color: '#ffffff',
    },
    {
      type: 'text',
      text: '【クーポン承認番号】',
      margin: 'xl',
    },
    {
      type: 'text',
      text: `『${approvalNo}』`,
      margin: 'none',
    },
    {
      type: 'text',
      text: '【クーポン使用方法】',
      margin: 'md',
    },
    {
      type: 'text',
      text: '店舗スタッフに、	\n 『クーポン承認番号』 \n 『共通クーポンコード』\n をご提示ください。',
      wrap: true,
    },
    {
      type: 'text',
      text: '＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝',
      wrap: true,
      margin: 'lg',
    },
    {
      type: 'text',
      text: '【オススメポイント】',
      margin: 'md',
    },
    {
      type: 'text',
      text: '■お店の『ここが良かった！』',
      margin: 'md',
      wrap: true,
    },
    {
      type: 'text',
      text: `${feedback && feedback[0] ? feedback[0].answer : '-'}`,
      wrap: true,
    },
    {
      type: 'text',
      text: '＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝',
      margin: 'md',
    },
    {
      type: 'text',
      text: 'お近くの店舗を探す',
      margin: 'lg',
    },
    {
      type: 'text',
      text: '（メガネスーパーを始めとするビジョナリーホールディングス各店舗）',
      wrap: true,
    },
    {
      type: 'text',
      text: 'https://www.meganesuper.co.jp/shops/',
      color: "#0000ff",
      action: {
        type: 'uri',
        label: 'action',
        uri: 'https://www.meganesuper.co.jp/shops/',
        altUri: {
          desktop: 'https://www.meganesuper.co.jp/shops/',
        },
      },
      wrap: true,
    },
    {
      type: 'text',
      text: '＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝',
    },
  ]
}

export default function generateFlexMessage(
  name: string,
  approvalNo: string,
  couponContents: couponContents,
  feedback: Array<Domain.LINECoupon.Survey>,
) {
  // Generate LINE Flex Message
  const contents = insertCommentIfExist(feedback, createBaseContents(name, approvalNo, couponContents, feedback))
  return {
    type: 'bubble',
    body: {
      type: 'box',
      layout: 'vertical',
      contents: contents,
    },
  }
}
