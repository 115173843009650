import { Dispatch } from 'redux'
import { Api } from '../../lib'
import { LINECoupon } from '../domain'
import { setCouponContents } from '../modules/couponContents'
import { setSurveyContents } from '../modules/surveyContents'

const PATH_TO_SURVEY_RESOURCE = 'survey-contents/survey.json'
const PATH_TO_COUPON_RESOURCE = 'coupon-contents/coupon.json'

type SurveyQuestionnaires = LINECoupon.SurveyQuestionnaires
type CouponContents = LINECoupon.CouponContents

function didGetSurveyContents(dispatch: Dispatch, data: SurveyQuestionnaires) {
  dispatch(setSurveyContents(data))
}

function didGetCouponContents(dispatch: Dispatch, data: CouponContents) {
  dispatch(setCouponContents(data))
}

export function getSurveyContents() {
  return async function(dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.get,
      PATH_TO_SURVEY_RESOURCE,
      null,
      dispatch,
      didGetSurveyContents,
      undefined,
      true,
    )
  }
}

export function getCouponContents(reloader: () => void) {
  const options = {
    reload: reloader,
  }
  return async function(dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.get,
      PATH_TO_COUPON_RESOURCE,
      null,
      dispatch,
      didGetCouponContents,
      options,
      true,
    )
  }
}
