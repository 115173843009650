import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { COLORS } from '../../../constants'

const styles = StyleSheet.create({
  content: {
    fontSize: 16,
    letterSpacing: 0.3,
    lineHeight: 26,
  },
  jumpToTop: {
    fontSize: 14,
    textAlign: 'right',
    color: COLORS.ACCENT,
    marginHorizontal: 16,
    marginVertical: 28,
  },
  qaHeadline: {
    fontSize: 16,
    letterSpacing: 0.3,
    lineHeight: 26,
    flex: 1,
    alignItems: 'flex-start',
    width: '100%',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: COLORS.LIGHTGRAY,
    marginVertical: 16,
  },
  qaHeadlineText: {
    fontSize: 16,
    textAlign: 'center',
    marginVertical: 16,
  },
})

interface Props {
  headline: string
  answer: string
  qaId: string
}

export default function QA(props: Props) {
  return (
    <View nativeID={props.qaId}>
      <View style={styles.qaHeadline}>
        <Text style={styles.qaHeadlineText}>{props.headline}</Text>
      </View>
      <Text style={styles.content}>{props.answer}</Text>
      <Text style={styles.jumpToTop}>
        <TouchableOpacity
          onPress={() => {
            window.scrollTo(0, 0)
          }}>
          ページ上部へ戻る
        </TouchableOpacity>
      </Text>
    </View>
  )
}
