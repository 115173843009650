export interface FAQ {
  readonly id: string
  readonly question: string
  readonly answer: string
}

// FAQ Links by Category
export const ABOUT_PROGRAM: Array<FAQ> = [
  {
    id: '1',
    question: 'アイケアアンバサダーとは何ですか？',
    answer: `株式会社ビジョナリーホールディングス（当社グループ会社は、当社ホームページに掲載されています）\
が提供するご紹介プログラムです。当社が運営する本LINE公式アカウントから、\
LINEで簡単にご家族・ご友人等へクーポン・メッセージを送り、ご紹介ができるサービスです。`,
  },
  {
    id: '2',
    question: '紹介する時には何ができますか？',
    answer: `本サービスにご登録されたお客様ご自身が、\
ご家族・ご友人等のお勧めされたい方にLINEのトーク上に当社グループ店舗で利用できるクーポンを送付できます。`,
  },
  {
    id: '3',
    question: 'どんな特典がありますか？',
    answer: `本サービスにご登録された紹介元ユーザーの方が、\
紹介先ユーザーの方に送付されたクーポンを店舗で商品に利用することで、\
紹介元ユーザーの方に紹介特典チケットが付与されます。\
（具体的な特典については『紹介元ユーザーに付与される特典チケットについて』をご覧ください）
また、ご紹介頂く紹介先ユーザーの方が店舗で利用する商品の購入金額・サービス加入内容に応じて特典チケットが継続して付与されます。`,
  },
  {
    id: '4',
    question: '利用を始めるにはどうすればいいでしょうか？',
    answer: `アイケアアンバサダーのLINE公式アカウントを友達追加して頂き、\
その後下記の会員登録を行うと利用を始められます`,
  },
]

export const ABOUT_MEMBERSHIP: Array<FAQ> = [
  {
    id: '5',
    question: '会員登録を行うにはどうすればいいでしょうか？',
    answer: `店舗から発行されたメンバーズカードに記載されているメンバーズカード番号（16桁）、\
登録するメールアドレスをご用意頂き、アイケアアンバサダーのLINE公式アカウントを友だち追加後、\
カード情報登録画面へ指定の項目に入力しご登録ください。`,
  },
  {
    id: '6',
    question: '会員登録をしなくても利用できますか',
    answer: `会員登録が必須です。`,
  },
  {
    id: '7',
    question: '会員になるのに会費はかかりますか',
    answer: `会員登録は無料です。登録料や年会費、利用料などは一切ありません。`,
  },
  {
    id: '8',
    question: 'メンバーズカード番号・メールアドレスの変更はできますか？',
    answer: `はい、できます。
登録されたメンバーズカード番号・メールアドレスのご変更は下記の「お問い合わせフォーム」\
もしくはフリーダイヤルにてご連絡ください。
「お問い合わせフォーム」https://www.meganesuper.net/shop/a/aL-QA/
※内容欄にお問い合わせ内容を記載してください。
フリーダイヤル：0120-209-060`,
  },
  {
    id: '9',
    question: '登録できません、ログインできません。',
    answer: `下記のいずれかをご確認ください。
・ご登録頂いたメンバーズカード番号の間違いはありませんか？
メンバーズカード番号を誤って入力されると登録・ログインができません。\
ご入力いただく際には、全角/半角の入力切替もご確認ください。`,
  },
  {
    id: '10',
    question: 'メンバーズカード番号忘れてしまいました、どうすればいいでしょうか？',
    answer: `下記の「お問い合わせフォーム」もしくはフリーダイヤルにてご連絡ください。
「お問い合わせフォーム」https://www.meganesuper.net/shop/a/aL-QA/
※内容欄にお問い合わせ内容を記載してください。
フリーダイヤル：0120-209-060`,
  },
]

export const ABOUT_COUPON: Array<FAQ> = [
  {
    id: '11',
    question: 'クーポンとは何ですか？',
    answer: `紹介元ユーザーの方から紹介先ユーザーの方に送られる割引券です。`,
  },
  {
    id: '12',
    question: 'クーポンはどうやって使えますか？',
    answer: `当社グループの小売店舗にて、クーポン画面に記載されている『クーポンコード』と\
『承認番号』を店舗スタッフに提示頂く事で対象商品に使用頂けます。`,
  },
  {
    id: '13',
    question: 'クーポンの対象外商品、サービスはありますか？',
    answer: `SALE・新作の商品、検査・リラクゼーション等の有料サービスはクーポン対象外です。
また、他クーポンとの併用はできません。`,
  },
  {
    id: '14',
    question: 'クーポンに有効期限はありますか？',
    answer: `特に有効期限はございません。ただし、時期・内容に応じて有効期限を設ける場合があります。
紹介先ユーザーの方に発行するクーポンに詳細が記載されています。`,
  },
  {
    id: '15',
    question: '紹介クーポンを送信したいのですが、エラーがでて送れません。',
    answer: `紹介クーポンの送信には、LINEバージョン10.3.0以降が必要です。iOS、Android各ストアにてLINEバージョンの更新をお願いします。`,
  },
  {
    id: '16',
    question: 'クーポンが送付されません。なぜですか？',
    answer: `原因を調査させていただきます。
お手数ですが、下記の「お問い合わせフォーム」もしくはフリーダイヤルにてご連絡ください。
「お問い合わせフォーム」https://www.meganesuper.net/shop/a/aL-QA/
※内容欄にお問い合わせ内容を記載してください。
フリーダイヤル：0120-209-060`,
  },
]

export const ABOUT_TICKET: Array<FAQ> = [
  {
    id: '17',
    question: '特典チケットとは何ですか？',
    answer: `特典チケットとは、Amazonギフト券等の特典と交換できる画面上に表示される引き換え券です。`,
  },
  {
    id: '18',
    question: '特典チケットはどうやったらもらえますか？',
    answer: `紹介元ユーザーが発行するクーポンを、紹介先ユーザーが店舗にて利用した際、\
メガネ37,000円、コンタクトレンズ10,000円、補聴器200,000円の金額ごとの購入で1枚付与されます。\
また、コンタクト定期便サービス、メガネハイパー保証サービス・\
子供安心プランサービスの加入時にチケット1枚付与いたします。`,
  },
  {
    id: '19',
    question: '特典チケットはどのように使えますか？',
    answer: `特典チケットは1枚ためるごとにAmazonギフト券と交換できます。\
特典チケットは5枚ためるごとに、カタログギフトや体験ギフト等の特別なギフトと交換できます。
特典チケット確認画面にて、『交換する』を選択すると特典と交換できます。`,
  },
  {
    id: '20',
    question: '特典チケットの引換期限はありますか？',
    answer: `あります、特典チケット発行から1年です。`,
  },
  {
    id: '21',
    question: '特典チケットやギフトが届きません。なぜですか？',
    answer: `原因を調査させていただきます。
お手数ですが、下記の「お問い合わせフォーム」もしくはフリーダイヤルにてご連絡ください。
「お問い合わせフォーム」https://www.meganesuper.net/shop/a/aL-QA/
※内容欄にお問い合わせ内容を記載してください。
フリーダイヤル：0120-209-060
また、紹介先ユーザーが店舗にて購入後約2週間前後の期間をとって付与させていただきます。`,
  },
  {
    id: '22',
    question: '特典チケットやギフトが表示されません。なぜですか？',
    answer: `原因を調査させていただきます。
お手数ですが、下記の「お問い合わせフォーム」もしくはフリーダイヤルにてご連絡ください。
「お問い合わせフォーム」https://www.meganesuper.net/shop/a/aL-QA/
※内容欄にお問い合わせ内容を記載してください。
フリーダイヤル：0120-209-060`,
  },
  {
    id: '23',
    question: '特典チケットやギフトは買い取ってもらえますか？',
    answer: `特典チケットやギフトの買い取りは行っておりません。`,
  },
  {
    id: '24',
    question: '特典チケットやギフトは譲渡できますか？',
    answer: `特典チケットや交換して取得したギフト等の特典を第三者に譲渡（有償のみ、無償は除く）することはできません。`,
  },
  {
    id: '25',
    question: 'アイケアアンバサダーのLINE公式アカウントをブロックしてもインセンティブの交換通知は受け取れますか？',
    answer: `アカウントをブロックした場合はインセンティブを交換した際に通知される交換通知は受け取れません。
また、特別なギフトの場合はURLが届かず、Amazonギフトコードもチケット交換画面からしか確認が出来ません。`,
  },
  {
    id: '26',
    question: '特典チケットの引換期限は何時まで可能ですか？',
    answer: `引換期限当日の23:59:59まで可能です。
例：チケット引換期限 : 2023/12/31　の場合　2023/12/31 23:59:59まで引換可能`,
  },
]

export const ABOUT_LINE_PERSONAL_DATA: Array<FAQ> = [
  {
    id: '27',
    question: '紹介した家族や友人の情報は何かに使われますか？',
    answer: `当社及びグループ会社では、ご家族やご友人の情報は取得しておりません。`,
  },
]

export const ABOUT_LINE_DATA_INHERITANCE: Array<FAQ> = [
  {
    id: '28',
    question: '新しいスマートフォンに変更する際に、特典チケットやギフトを引き継げますか？',
    answer: `新しいスマートフォンに、本サービスで使用しているLINEアカウントを引き継ぐと可能です。\
新しいLINEアカウントになり、登録済のメンバーズカード番号で登録しようとするとエラーになりますのでご注意ください。\
エラーになったその際はお手数ですが、下記の「お問い合わせフォーム」もしくはフリーダイヤルにてご連絡ください。
「お問い合わせフォーム」https://www.meganesuper.net/shop/a/aL-QA/
※内容欄にお問い合わせ内容を記載してください。
フリーダイヤル：0120-209-060`,
  },
]
