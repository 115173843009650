import * as React from 'react'
import { Linking, StyleSheet, View, ViewStyle } from 'react-native'
import { COLORS } from '../../../constants'
import Footer from '../../common/Footer'
import Step1Img from './../../../assets/images/Step1.png'
import Step2Img from './../../../assets/images/Step2.png'
import Step3Img from './../../../assets/images/Step3.png'
import Button from './Button'
import Step from './Step'
import Title from './Title'

const styles = StyleSheet.create({
  border: {
    borderTopWidth: 1,
    borderColor: COLORS.LIGHTGRAY,
    marginVertical: 20,
    width: '100%',
  },
  step1ImageSize: {
    width: 242,
    height: 104,
  },
  step2ImageSize: {
    width: 180,
    height: 116,
  },
  step3ImageSize: {
    width: 288,
    height: 93,
  },
})

interface Step {
  stepLabel: string
  title: string
  imageSource: string
  subText: string
  imageStyle: ViewStyle
}

const stepsContents: Step[] = [
  {
    stepLabel: '手順１',
    title: 'クーポンを友だちに送ろう',
    imageSource: Step1Img,
    subText: 'メンバーズカードとLINEを連携したらお得なクーポンを友だちに送ろう！',
    imageStyle: styles.step1ImageSize,
  },
  {
    stepLabel: '手順２',
    title: '送ったクーポンで購入',
    imageSource: Step2Img,
    subText:
      '送ったクーポンで友だちにメガネスーパーを始めとするビジョナリーホールディングス各店舗の商品を購入したり、サービスを利用してもらおう！',
    imageStyle: styles.step2ImageSize,
  },
  {
    stepLabel: '手順３',
    title: 'チケットが届いたら\n交換するだけ！',
    imageSource: Step3Img,
    subText:
      'チケットが届いたらギフト券と交換しましょう！チケットの枚数が貯まるとカタログギフトなど、特別な豪華ギフトと交換することができます。（時期により内容の変更があります）',
    imageStyle: styles.step3ImageSize,
  },
]

const createStep = (step: Step, i: number) => {
  return (
    <View key={i}>
      <View style={styles.border} />
      <Step
        stepLabel={step.stepLabel}
        title={step.title}
        imageSource={step.imageSource}
        subText={step.subText}
        imageStyle={step.imageStyle}
      />
    </View>
  )
}

const steps = stepsContents.map(createStep)

export default function LP() {
  const TICKETS = `${process.env.REACT_APP_BASE_PATH}/tickets`
  const OpneUrl = (url: string) => {
    Linking.openURL(url)
  }

  return (
    <View>
      <Title />

      {steps}
      <Button
        buttonText={'チケットを確認する'}
        onPress={() => {
          OpneUrl(TICKETS)
        }}
      />
      <Footer />
    </View>
  )
}
